// import { Application } from "@hotwired/stimulus"
// // const application = Application.start()
// window.Stimulus = Application.start()

import { Application } from "@hotwired/stimulus"

const application = Application.start()

// Configure Stimulus development experience
application.debug = false
window.Stimulus   = application

export { application }


import AccordionController from "./accordion_controller.js"
application.register("accordion", AccordionController)

import AnimateController from "./animate_controller.js"
application.register("animate", AnimateController)

import AutosaveController from "./autosave_controller.js"
application.register("autosave", AutosaveController)

import BlocksController from "./blocks_controller.js"
application.register("blocks", BlocksController)

import CartController from "./cart_controller.js"
application.register("cart", CartController)

import ColorPickerController from "./color_picker_controller.js"
application.register("color-picker", ColorPickerController)

import DelegateClickController from "./delegate_click_controller.js"
application.register("delegate-click", DelegateClickController)

import DropdownController from "./dropdown_controller.js"
application.register("dropdown", DropdownController)

import FlashController from "./flash_controller.js"
application.register("flash", FlashController)

import FormController from "./form_controller.js"
application.register("form", FormController)

import ModalController from "./modal_controller.js"
application.register("modal", ModalController)

import NestedFormController from "./nested_form_controller.js"
application.register("nested-form", NestedFormController)

import RedactorController from "./redactor_controller.js"
application.register("redactor", RedactorController)

import RedactorxController from "./redactorx_controller.js"
application.register("redactorx", RedactorxController)

import RevealController from "./reveal_controller.js"
application.register("reveal", RevealController)

import ScrollToController from "./scroll_to_controller.js"
application.register("scroll-to", ScrollToController)

import SidecarController from "./sidecar_controller.js"
application.register("sidecar", SidecarController)

import SortableController from "./sortable_controller.js"
application.register("sortable", SortableController)

import TabsController from "./tabs_controller.js"
application.register("tabs", TabsController)

import TextareaAutogrowController from "./textarea_autogrow_controller.js"
application.register("textarea-autogrow", TextareaAutogrowController)

import ToggleController from "./toggle_controller.js"
application.register("toggle", ToggleController)

import CtaController from "./cta_controller.js"
application.register("cta", CtaController)

import CarouselController from "./carousel_controller.js"
application.register("carousel", CarouselController)

import MapsController from "./maps_controller.js"
application.register("maps", MapsController)

import IframePreviewController from "./iframe_preview_controller.js"
application.register("iframe-preview", IframePreviewController)
