// import Rails from "@rails/ujs"
// https://github.com/rails/request.js

import { Controller } from "@hotwired/stimulus"
import { patch } from '@rails/request.js'
import Sortable from 'sortablejs'

export default class extends Controller {
  
  static targets = [ "list"]
  
  connect() {
    // console.log(this.data.get('handle'))

    this.sortable = Sortable.create(this.listTarget, {
      handle: this.data.get('handle'),
      animation: 150,
      easing: "cubic-bezier(1, 0, 0, 1)",
      onEnd: this.end.bind(this),
      filter: '.filtered',
  		group: 'nested',
  		fallbackOnBody: true,
  		swapThreshold: 0.5,
    })
    
    // Nested demo
    var nestedSortables = [].slice.call(document.querySelectorAll('.nested-sortable'));

    // Loop through each nested sortable element
    for (var i = 0; i < nestedSortables.length; i++) {
    	this.nested_sortable = new Sortable(nestedSortables[i], {
        filter: '.filtered',
    		group: 'nested',
    		animation: 150,
    		fallbackOnBody: true,
    		swapThreshold: 0.5,
        onEnd: this.end.bind(this),
      // Element dragging started
      	
    	});
    }
    
  }
  async disable(event) {
    console.log(event.item)
     var behavior = event.item.getAttribute('data-behavior')
     if(behavior == "Folder"){
       console.log("k")
       for (var i = 0; i < this.nested_sortable.length; i++) {
       var state = this.nested_sortable[i].option("disabled"); // get
       this.nested_sortable[i].option("disabled", true); // set
     }
   }
     

  }
  
  async end(event) {
    //Get parent id for nested list
    var parent_id = event.to.getAttribute('data-parent')
    
    let formData = new FormData()
    formData.append("new_index", event.newIndex);
    formData.append("parent_id", parent_id);
    // formData.append("index_array", sortable.toArray());
  
    const response = await patch(event.item.getAttribute('data-url'), { 
      responseKind: "turbo-stream",
      body: formData
    })
    
    if (response.ok) {
      // console.log('Sequence reordered')
    } else {
      // console.error('Error')
    }
  }
  
  // connect() {
 //
 //    var sortable = Sortable.create(this.listTarget, {
 //      delay: 0,
 //      // animation: 150,
 //      // direction: 'horizontal',
 //      // handle: '[data-sortable-handle]',
 //
 //      // onSort: this.saveSort.bind(this, sortable)
 //      onSort: async function(evt){
 //        let formData = new FormData()
 //        formData.append("new_index", evt.newIndex);
 //        // formData.append("index_array", sortable.toArray());
 //
 //        const response = await post(evt.item.getAttribute('data-url'), {
 //          responseKind: "turbo-stream",
 //          body: formData
 //        })
 //
 //        if (response.ok) {
 //        }
 //
 //      }
 //    });
 //  }
  
 
}

//
// import { Controller } from "stimulus"
// import Sortable from "sortablejs"
//
// export default class extends Controller {
//   connect() {
//     this.sortable = Sortable.create(this.element, {
//       group: 'shared',
//       animation: 150,
//       onEnd: this.end.bind(this)
//     })
//   }
//
//   end(event) {
//     let id = event.item.dataset.id
//     let data = new FormData()
//     data.append("position", event.newIndex + 1)
//
//     Rails.ajax({
//       url: this.data.get("url").replace(":id", id),
//       type: 'PATCH',
//       data: data
//     })
//   }
// }