// import { TweenMax, TweenLite} from 'gsap';
//
// import { Controller } from "stimulus"
//
// export default class extends Controller {
//
//   static targets = [ "slideUp"]
//
//
//   connect() {
//
//     const timeline = new TimelineMax()
//
//     const event = (typeof Turbolinks == "object" && Turbolinks.supported) ? "turbolinks:load" : 'DOMContentLoaded'
//
//     // document.addEventListener(event, () => {
//     //   // window.addEventListener('DOMContentLoaded', (event) => {
//     //     timeline.fromTo(this.slideUpTarget, 0.7, {y: 20, opacity: 0 }, { opacity: 1, y: 0, ease: Power2.easeOut, delay: .2}, '0.6')
//     //   // });
//     // })
//
//     // wait until DOM is ready
//     document.addEventListener(event, () => {
//              timeline.fromTo(this.slideUpTarget, 0.7, {y: 20, opacity: 0 }, { opacity: 1, y: 0, ease: Power2.easeOut, delay: .2}, '0.6')
//
//         // wait until window is loaded - all images, styles-sheets, fonts, links, and other media assets
//         // you could also use addEventListener() instead
//         window.onload = function() {
//              timeline.fromTo(this.slideUpTarget, 0.7, {y: 20, opacity: 0 }, { opacity: 1, y: 0, ease: Power2.easeOut, delay: .2}, '0.6')
//
//            // OPTIONAL - waits til next tick render to run code (prevents running in the middle of render tick)
//            window.requestAnimationFrame(function() {
//              timeline.fromTo(this.slideUpTarget, 0.7, {y: 20, opacity: 0 }, { opacity: 1, y: 0, ease: Power2.easeOut, delay: .2}, '0.6')
//            });
//         };
//     });
//
//
//   }
// }