import { Controller } from '@hotwired/stimulus'


export default class extends Controller {
  static targets = ['iframe', 'container']

  connect() {

    //Get Iframe
    var iframe = this.iframeTarget
    var timeout = iframe.dataset.timeout
    
    //Get Container
    var container = this.element
    var height = "0px"
      
    //Timeout
    setTimeout(function(){ iframe.src = iframe.dataset.src }, timeout);
  
    //Onload resize
    iframe.onload = function () {          
      var element = iframe.contentWindow.document.getElementsByTagName("header")[0];
      if(typeof(element) != 'undefined' && element != null){
        height = element.offsetHeight;
      }

      var element = iframe.contentWindow.document.getElementsByTagName("article")[0];
      
      if(typeof(element) != 'undefined' && element != null){
        height = element.offsetHeight;
      }
      
      //Set Container
      container.style.height = height * 0.6 + 'px';      
      //Set Iframe
      iframe.style.height = height + 'px';      
      //Show Iframe
      iframe.style.opacity = 1;          
    }        
  }
  

  
  
}