import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  
  static targets = ['input', 'select']
  
  connect() {
    // console.log("Hello World")
  }

  change_type() {
    var val = this.selectTarget.value
    //Fill in target with page slug
    if(val != "web"){
      this.inputTarget.value = val
    }
  }
  
  convert_input() {    
    //Get value from textfield
    var val = this.inputTarget.value    
            
    //If input is email convert to mailto
    var is_email = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;      
    if (is_email.test(val)){
      this.inputTarget.value = "mailto:" + val      
    }
      
    //If input is phone convert to tel
    var is_number = /^\d+(-\d+)*$/;
    if (is_number.test(val)){
      this.inputTarget.value = "tel:" + val      
    }
      
    //If input is domain convert to https    
    const isValidUrl = urlString=> {
      var urlPattern = new RegExp('^(https?:\\/\\/)?'+ // validate protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // validate domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // validate OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // validate port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // validate query string
      '(\\#[-a-z\\d_]*)?$','i'); // validate fragment locator
      return !!urlPattern.test(urlString);
    }
                                  
    if (isValidUrl(val)){
      //Check if https is present
      if (!/^(?:f|ht)tps?\:\/\//.test(val)) {
        this.inputTarget.value = "https://" + val;
      }
    }            
  }  
  
}
