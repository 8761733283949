// import Rails from "@rails/ujs"
import { Controller } from "@hotwired/stimulus"
import { patch } from '@rails/request.js'

//Import Redactor editor
import './../src/redactor.js'
import './../src/alignment.js'

export default class extends Controller {
  
  static targets = [ "output" ]

  connect() {   
    var contentTarget = this.element    
    // console.log(contentTarget.tagName)
    var url = this.data.get("url")
    var model = this.data.get("model")
    var placeholder = this.data.get("placeholder") || "Begin met typen"
    let formData = new FormData()
    
    var timeout  = 0
    var duration = 1000
    
    if (contentTarget.tagName === 'TEXTAREA' ){       
      $R(this.element, {
        plugins: ['alignment'],
        
        styles: true,
        stylesClass: "content",
        
        air: true,
        clickToEdit: true,
        // clickToCancel: { title: 'Cancel' },
        
        buttonsTextLabeled: false,
        placeholder: "Begin met typen",   
             
        // buttons: ['format', 'bold', 'italic', 'ul', 'ol', 'link'],
        
        formatting: ['p', 'p2', 'h1', 'h2', 'h3', 'h4'],
        formattingAdd: {
          "p2-add": {
              title: 'Paragraph 2',
              api: 'module.block.format',
              args: {
                  'tag': 'p',
                  'class': 'text-p2'
              }
          },
          "p3-add": {
              title: 'Paragraph 3',
              api: 'module.block.format',
              args: {
                  'tag': 'p',
                  'class': 'text-p3'
              }
          },
        },
        
        callbacks: {
          synced: async function(e){
            //Clear TimeOut
            clearTimeout(timeout)
            //Timeout to prevent saving too often
            timeout = setTimeout(() => {
              formData.append(model, contentTarget.value )
              
              const response =  patch(url, { 
                // responseKind: "turbo-stream",
                responseKind: "json",
                body: formData
              })  
              

            }, duration)

          }
        }
      })
    }else{      
      $R(this.element, {
        plugins: ['alignment'],
        pastePlainText: true,
        styles: false, 
        placeholder: placeholder,
        buttons: [
          'bold',
          'italic',
          'link'
        ],
        formatting: ['p', 'p2', 'h1', 'h2', 'h3', 'h4'],
        formattingAdd: {
          "p2-add": {
              title: 'Paragraph 2',
              api: 'module.block.format',
              args: {
                  'tag': 'p',
                  'class': 'text-p2'
              }
          },
          "p3-add": {
              title: 'Paragraph 3',
              api: 'module.block.format',
              args: {
                  'tag': 'p',
                  'class': 'text-p3'
              }
          },
        },
        air: true,
        callbacks: {
          keyup: function(e){
            //Clear TimeOut
            clearTimeout(timeout)
            //Timeout to prevent saving too often
            timeout = setTimeout(() => {
              formData.append(model, contentTarget.innerHTML )
              
              const response =  patch(url, { 
                responseKind: "turbo-stream",
                body: formData
              })  
              

            }, duration)
          }
        }
      })
      
    }
                         
  }
  
  
  // get authenticity_token() {
  //   return document.querySelector("meta[name='csrf-token']").getAttribute("content")
  // }
  
}
