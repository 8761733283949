// https://github.com/stimulus-components/stimulus-reveal-controller

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  // hasHiddenClass: boolean
  // hiddenClass: string
  // itemTargets: HTMLElement[]
  // class: string

  static targets = ['item']
  static classes = ['hidden']

  connect() {
    this.class = this.hasHiddenClass ? this.hiddenClass : 'hidden'
  }

  toggle() {
    this.itemTargets.forEach(item => {
      item.classList.toggle(this.class)
    })
  }

  show() {
    this.itemTargets.forEach(item => {
      item.classList.remove(this.class)
    })
  }

  hide() {
    this.itemTargets.forEach(item => {
      item.classList.add(this.class)
    })
  }
}