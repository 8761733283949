import { Controller } from '@hotwired/stimulus'

export default class extends Controller {


  initialize() {
    this.setInputAttributes()
    this.update()
  }

  update() {
    this.element.style.height = "auto"
    this.element.style.height = `${this.element.scrollHeight}px`
  }

  setInputAttributes() {
    this.element.setAttribute("style", `height: ${this.element.scrollHeight}px; overflow-y: hidden;`)
  }
}
