import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['message']

  connect() {
    this.timeout = setTimeout(this.closeFlash, 2500);
  }

  disconnect() {
    clearTimeout(this.timeout);
  }

  closeFlash = () => {
    // this.flashBox.remove();
    this.element.style.display = 'none';
    // this.flashBox.fadeOut(300, () => {
    //   this.flashBox.remove();
    // });
  }

}