// /*
// <div data-controller="sidecar">
//   <a href="#" data-action="click->sidecar#open">
//     <span>Open Modal</span>
//   </a>
//
//   <!-- Modal Container -->
//   <div data-target="sidebar.container">
//    </div>
// </div>
// */
//
// // import TweenLite from "gsap/TweenLite";
// // import TweenMax from "gsap/TweenMax";
//
// import { Controller } from "stimulus"
//
// export default class extends Controller {
//
//   static targets = ['container']
//
//   connect() {
//     console.log('connected');
//
//     this.toggleClass    = this.data.get('class') || 'hidden'
//   }
//
//   open(e){
//     e.preventDefault()
//
//     // Add right padding to the body so the page doesn't shift
//     // when we disable scrolling
//     let scrollbarWidth = window.innerWidth - document.documentElement.clientWidth
//     document.body.style.paddingRight = `${scrollbarWidth}px`
//
//     // Set overflow hidden to prevent scrolling of background
//     // This must happen after the scrollbar calculation
//     document.body.style.overflow = 'hidden'
//
//     // Unhide the modal
//     this.containerTarget.classList.remove(this.toggleClass)
//     // this.containerTarget.classList.remove('hidden')
//
//     console.log('test')
//   }
//
//
//   close(e) {
//     e.preventDefault()
//
//     // Remove tweaks for scrollbar
//     document.body.style.paddingRight = null
//     document.body.style.overflow = null
//
//     // Hide the modal
//     this.containerTarget.classList.add(this.toggleClass)
//   }
//
//   closeWithKeyboard(e) {
//     if (e.keyCode == 27 && !this.containerTarget.classList.contains(this.toggleClass)) {
//       this.close(e)
//     }
//   }
//
// }